import { PayloadAction } from "@reduxjs/toolkit";
import { takeLatest, put, call } from "redux-saga/effects";
import testStatusService from "./service";
import {
  testStatusListRequest,
  testStatusListSuccess,
} from "./slices/test-status-slice";

function* getTestStatusList(action: PayloadAction<any>) {
  try {
    const res = yield call(
      testStatusService.getDownloadTestStatus,
      action?.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(testStatusListSuccess(res.data.data));
    } else if (res.data.status === "error") {
      console.log(" error", res);
    }
  } catch (e) {}
}

export default function* TestStatusSaga() {
  yield takeLatest(testStatusListRequest.type, getTestStatusList);
}
