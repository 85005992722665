import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ILearnListRequestBody, ILearnListSuccessBody, IInitState, IDeleteRequestBody } from "../interfaces";


const initialState = {
  loading: false,
  error: "",
  data: {
    synopsis: [],
    qa: [],
    summary: [],
    video: [],
    resources: [],
  },
};


const learnListSlice = createSlice({
  name: "learn-list",
  initialState,
  reducers: {
    learnListRequest(state: IInitState, action: PayloadAction<ILearnListRequestBody>) {
      state.loading = true;
    },
    learnListSuccess(state: IInitState, action: PayloadAction<ILearnListSuccessBody>) {
      console.log(action, "action")
      state.data[action.payload.key] = action.payload.data
    },
    deleteLearnListRequest(state: IInitState, action: PayloadAction<IDeleteRequestBody>) {
      state.loading = true
    },
    deleteLearnListSuccess(state) {
      return state
    },
    resetLearnList(state: IInitState) {
      state.data = {
        synopsis: [],
        qa: [],
        summary: [],
        video: [],
        resources: [],
      }
    }
  }
});
export const {
  learnListRequest,
  learnListSuccess,
  deleteLearnListRequest,
  deleteLearnListSuccess,
  resetLearnList
} = learnListSlice.actions;

export default learnListSlice.reducer;
