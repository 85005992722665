import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Box,
  Typography,
} from "@mui/material";
import request from "utils/axios/service";
import { Controller } from "react-hook-form";
import FieldError from "shared/organisms/dynamicform/FieldError";
import { makeStyles } from "@mui/styles";
import { isEmpty, objectDuplicationRemover } from "utils/helpers";
import { StyledSelect } from "./styles";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { SearchField } from "./search-field";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { updateSelectedCollegeList } from "pages/configue/slices/feature-config-slice";

interface IMultiSelctProps {
  field: any;
  control: any;
  errors: any;
  getValues: any;
  setValue?: any;
  watch?: any;
  nameValue?: any;
  indexNumber?: any;
  name?: any;
}

const useStyles = makeStyles({
  border: {
    "& .css-m1opko-MuiFormLabel-root-MuiInputLabel-root": {
      background: "white",
      paddingRight: "5px",
      paddingLeft: "5px",
    },
  },
});

const MultiSelectSearchOnly = ({
  field,
  control,
  errors,
  setValue,
  getValues,
  watch,
  nameValue,
  indexNumber,
  name,
}: IMultiSelctProps) => {
  const multiFormError =
    errors && errors?.[name]?.[indexNumber]?.[field.id]?.message;
  const singleFormError = errors && errors[field.id]?.message;
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<any>([]);
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState("");
  const [flag, setFlag] = useState(false);
  const [collegeLoading, setCollegeListLoading] = useState(false);
  const { data: metaData, updatedAt } = useAppSelector(
    (state) => state.metadataReducer
  );
  const { data, error, loading, selectedCollegeList } = useAppSelector(
    (state) => state.featureConfigApiSlice
  );

  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange = (event: any, onChange: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      // if (selected.length === options.length) {
      // if (selected.length === filteredOptions.length) {
      if (checkValuesInArray(filteredOptions, selected)) {
        const remainingValues = selected.filter(
          (item) => !filteredOptions.some((option) => option.value === item)
        );
        setSelected(remainingValues);
        onChange(remainingValues);
        dispatch(
          updateSelectedCollegeList({
            key: field?.reduxSelectKey,
            value: reduxSelectedCollegeList(remainingValues),
          })
        );
      } else {
        // const filteredArr = options.map((option: any) => option.value);
        const filteredArr = filteredOptions.map((option: any) => option.value);
        value.pop();
        const oldAndFilterValue = [...filteredArr, ...value];
        setSelected(oldAndFilterValue);
        onChange(oldAndFilterValue);
        dispatch(
          updateSelectedCollegeList({
            key: field?.reduxSelectKey,
            value: reduxSelectedCollegeList(oldAndFilterValue),
          })
        );
      }
      return;
    }
    setSelected(value);
    onChange(value);
    dispatch(
      updateSelectedCollegeList({
        key: field?.reduxSelectKey,
        value: reduxSelectedCollegeList(value),
      })
    );
  };
  const reduxSelectedCollegeList = (valuesArray) => {
    if (!isEmpty(valuesArray)) {
      const totalOptions = [
        ...filteredOptions,
        ...selectedCollegeList[`${field?.reduxSelectKey}`],
      ];
      const filteredTotalOpt: any = totalOptions.filter((option) =>
        valuesArray.includes(option.value)
      );
      return objectDuplicationRemover(filteredTotalOpt, "value");
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (metaData) {
      const jsonMetaData = metaData;
      const temp = jsonMetaData[field.list];
      let opts: any = [];
      if (temp && temp.length > 0) {
        opts = temp.map((item: any) => ({
          label: item[field.keyValue.label],
          value: item[field.keyValue.value],
        }));
        setOptions(objectDuplicationRemover(opts, "value"));
        setFilteredOptions(objectDuplicationRemover(opts, "value"));
      } else if (!isEmpty(options)) {
        setOptions([]);
        setFilteredOptions([]);
      }
    }
  }, [watch, updatedAt]);

  const hasOptions = filteredOptions && filteredOptions.length > 0;

  const classes = useStyles();

  const updateMetaData = (listData) => {
    let metadataList = { ...metaData };
    metadataList[field.list] = listData;
    dispatch(metadataSuccess(metadataList));
  };

  const convertToLabelValueFormat = (field: any, data) => {
    if (!data || data.length === 0) return [];
    const convertOts = data.map((item: any) => ({
      label: item[field.keyValue.label],
      value: item[field.keyValue.value],
    }));
  };

  const getDataFromApi = async (cb, searchVal) => {
    setCollegeListLoading(true);
    const res: any = await request.get(field.endpoint, {
      is_search: true,
      search: searchVal,
    });
    if (res && res.status === 200 && res.data.status === "success") {
      setCollegeListLoading(false);
      const dummyRes = await cb(res.data.data);
    } else {
      setCollegeListLoading(false);
    }
  };

  const checkValuesInArray = (filteredOptions, valuesArray): boolean => {
    const filteredValues = filteredOptions?.map((option) => option?.value);
    return filteredValues?.every((value) => valuesArray?.includes(value));
  };

  useEffect(() => {
    if (inputValue?.length > 0) {
      const searchedOptions = options.filter((option: any) => {
        if (option?.label.toLowerCase().includes(inputValue.toLowerCase())) {
          return option;
        }
      });
    } else {
      updateMetaData([]);
    }
  }, [options, flag]);

  const handleSearchChange = (event) => {
    const { value: searchValue } = event.target;
    setInputValue(searchValue);
    if (searchValue?.length > 2) {
      getDataFromApi(updateMetaData, searchValue);
    } else {
      updateMetaData([]);
      setOptions([]);
      setFilteredOptions([]);
    }
  };

  const handleKeyDown = (event) => {
    event.preventDefault();
  };

  return (
    <Grid item {...field.breakpoints}>
      {!isEmpty(selectedCollegeList[field?.reduxSelectKey]) && (
        <Typography variant="body1" component="p" gutterBottom>
          {/* {selectedCollegeList[field?.reduxSelectKey]
          ?.map((item) => item.label)
          .join(", ")} */}
          {selectedCollegeList[field?.reduxSelectKey]?.map((item, index) => (
            <span key={item.label}>
              {item.label}
              {index !==
                selectedCollegeList[field?.reduxSelectKey].length - 1 && ","}
              <br />
            </span>
          ))}
        </Typography>
      )}
      <Controller
        render={({ field: { onChange, value } }) => (
          <>
            <FormControl
              style={{ width: "100%" }}
              size="small"
              className={classes.border}
            >
              <InputLabel
                sx={{
                  color:
                    errors && (singleFormError || multiFormError)
                      ? "#E83845"
                      : "",
                  background: "white",
                }}
                // sx={{ background: "white" }}
                id="demo-multiple-checkbox-label"
              >
                {field.label}
              </InputLabel>
              <StyledSelect
                $readOnly={field.readOnly}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={value || []}
                required={field.required}
                disabled={field.disabled}
                style={field.style}
                error={multiFormError ? multiFormError : singleFormError}
                multiple
                inputProps={{
                  readOnly: field.readOnly ? field.readOnly : false,
                }}
                onChange={(event) => {
                  handleChange(event, onChange);
                }}
                renderValue={(selected: any) => {
                  const names = options.reduce((acc: string[], item: any) => {
                    if (value.includes(item.value))
                      return (acc = [...acc, item.label]);
                    return acc;
                  }, []);
                  const namesLength: any =
                    names.length > 1 ? `+${names.length - 1}` : "";
                  return names
                    .join(", ")
                    .replace(/(.{15})..+/, `$1...${namesLength}`);
                }}
                MenuProps={{
                  onKeyDown: { handleKeyDown },
                  autoFocus: true,
                  sx: {
                    "&& .MuiList-root": {
                      display: "flex",
                      flexDirection: "column",
                      maxHeight: "auto",
                      // margin: "10px",
                    },
                    "&& .MuiMenuItem-root": {
                      // padding: "8px",
                      width: "100%",
                    },
                  },
                }}
                name={name ? `${name}.${indexNumber}.${field.id}` : field.id}
              >
                {!hasOptions && (
                  <MenuItem disabled>{"No options found"}</MenuItem>
                )}
                {
                  <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                    <SearchField
                      inputValue={inputValue}
                      flag={flag}
                      setFlag={setFlag}
                      setInputValue={setInputValue}
                      handleSearchChange={handleSearchChange}
                    />
                  </MenuItem>
                }
                {collegeLoading && (
                  <Typography
                    variant="body1"
                    component="p"
                    gutterBottom
                    style={{ color: "red"}}
                  >
                    Loading...
                  </Typography>
                )}
                {/* {hasOptions && ( // select all
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: "" }}
                        checked={checkValuesInArray(filteredOptions, value)}
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: "" }}
                      primary="Select All"
                    />
                  </MenuItem>
                )} */}
                {hasOptions &&
                  filteredOptions.map((option: any) => (
                    // <div key={option.value} >
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox
                        checked={
                          value ? value.indexOf(option.value) > -1 : false
                        }
                      />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                    // </div>
                  ))}
              </StyledSelect>
            </FormControl>
            {field.showError ? (
              <FieldError field={field} errors={errors} />
            ) : (
              field.customizableError
            )}
          </>
        )}
        name={name ? `${name}.${indexNumber}.${field.id}` : field.id}
        control={control}
        defaultValue=""
      />
      {/* {errors && errors[field.id]?.message  && (
        <FieldError field={field} errors={errors} />
      )} */}

      {errors && (singleFormError || multiFormError) && (
        <FieldError
          field={field}
          errors={errors}
          name={name}
          indexNumber={indexNumber}
        />
      )}
    </Grid>
  );
};

export default MultiSelectSearchOnly;
