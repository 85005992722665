import { Button, Grid, Paper } from "@mui/material";
import useReactHookForm from "hooks/useReactHookForm";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getCollegeListData } from "utils/metadata-split/metaDataApiFetch";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import {
  getBatchFromClassId,
  getBatchFromClassIdReturn,
  getCollegeFromStateId,
  getCourseFromClassIdReturn,
  getSubBatchFromBatchId,
} from "utils/metadata-split/depedentApiFetch";
import { featureConfigRequest } from "pages/configue/slices/feature-config-slice";
import {
  createStdCourseInitialValues,
  createStdCourseValidationSchema,
  createStudentCourseProperties,
} from "./helper";
import { StudentCourseLookupRequest } from "pages/configue/slices/student-course-slice";
import {
  areArraysEqual,
  hasLengthGreaterThanZero,
  setReactFormValues,
} from "pages/configue/constants";
import { isEmpty } from "utils/helpers";
import { isCourseIdInMetadata } from "../helper";

export const CourseLookUpFilter = ({ setSearchPayload, setOffset }) => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    createStdCourseInitialValues,
    createStdCourseValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const [triggerSetValue, setTriggerSetValue] = useState(false);
  const { isLoading: isLoad, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );

  const convertArrayToString = (arr) => {
    const params = {};
    let payloadString = "";
    if (typeof arr == "string") {
      return arr;
    } else if (arr?.length > 0) {
      arr.forEach((sateid, i) => {
        if (i == arr.length - 1) {
          payloadString = payloadString + sateid;
        } else {
          payloadString = payloadString + sateid + ",";
        }
      });
      return payloadString;
    }
  };

  const clearFilter = () => {
    setValue("academic_year_list", []);
    setValue("reservation_status_list", []);
    setValue("class_list", []);
    setValue("batch_list", []);
    setValue("state_list", []);
    setValue("college_list", []);
    setValue("board_list", []);
    setValue("course_id", "");
    setValue("boolean_operation", "");

    setSearchPayload({});
    setOffset(0);
    updateMetaData([],"college_list")
  };

  const handleSearch = () => {
    const payload = {
      academic_year: convertArrayToString(watch()?.academic_year_list),
      registration_status: convertArrayToString(
        watch()?.reservation_status_list
      ),
      class: convertArrayToString(watch()?.class_list),
      batch: convertArrayToString(watch()?.batch_list),
      state: convertArrayToString(watch()?.state_list),
      college: convertArrayToString(watch()?.college_list),
      course: watch()?.course_id,
      limit: 10,
      offset: 0,
      boolean_operation: watch()?.boolean_operation == "AND" ? "AND" : "OR",
    };
    setSearchPayload(payload);
    setOffset(0);
  };
  const updateMetaData = async (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    await dispatch(metadataSuccess(metadataList));
    if (metaKey == "batch_list" && listData?.length == 0) {
      setValue("batch_list", []);
    }
    if (metaKey == "college_list" && listData?.length == 0) {
      setValue("college_list", []);
    }
  };

  const updateMetaDataClassIdChange = async (listData, metaKey,listData2, metaKey2) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    metadataList[metaKey2] = listData2;
    await dispatch(metadataSuccess(metadataList));
    if (metaKey == "batch_list" && listData?.length == 0) {
      setValue("batch_list", []);
    }
    if (metaKey2 == "course_list_dep" && listData2?.length == 0) {
      setValue("course_id", "");
    }
  };

  useEffect(() => {
    setTimeout(async() => {
    const call1= await getBatchFromClassIdReturn(watch()?.class_list, updateMetaData);
    const call2= await getCourseFromClassIdReturn(watch()?.class_list, updateMetaData);
     updateMetaDataClassIdChange(call1[0],call1[1],call2[0],call2[1])
    }, 2000);
  }, [watch()?.class_list]);

  useEffect(() => {
    setTimeout(async () => {
      await getCollegeFromStateId(watch()?.state_list, updateMetaData);
    }, 1000);
  }, [watch()?.state_list]);

  useEffect(() => {
    if (metaData?.batch_list?.length > 0 && !isLoad && triggerSetValue) {
      let matchedVales = setReactFormValues(
        metaData,
        "batch_list",
        watch()?.batch_list,
        "batch_id"
      );
      let isEqual = areArraysEqual(watch()?.batch_list, matchedVales);
      if (!isEqual) {
        setValue("batch_list", matchedVales);
      }
    }
  }, [metaData?.batch_list, triggerSetValue]);

  useEffect(() => {
    if (metaData?.college_list?.length > 0 && !isLoad && triggerSetValue) {
      let matchedVales = setReactFormValues(
        metaData,
        "college_list",
        watch()?.college_list,
        "college_id"
      );
      let isEqual = areArraysEqual(watch()?.college_list, matchedVales);
      if (!isEqual) {
        setValue("college_list", matchedVales);
      }
    }
  }, [metaData?.college_list]);

  useEffect(() => {
    if (
      !isEmpty(metaData?.course_list_dep?.length) &&
      !isLoad &&
      triggerSetValue &&
      isEmpty(watch()?.course_id)
    ) {
      const isCourseIdinList = isCourseIdInMetadata(
        metaData?.course_list_dep,
        "course_id",
        watch()?.course_id
      );
      if (!isCourseIdinList) {
        setValue("course_id", "");
      }
    }
  }, [metaData?.course_list_dep]);

  useEffect(() => {
    setTimeout(() => {
      setTriggerSetValue(true);
    }, 3000);
  }, []);

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={createStudentCourseProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <Button
              variant="contained"
              sx={{
                width: "70px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 42.5px 7px 48.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={clearFilter}
              disabled={hasLengthGreaterThanZero(watch(), "boolean_operation")}
            >
              clear
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "80px",
                height: "40px",
                marginTop: "15px",
                padding: "7px 42.5px 7px 48.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleSearch}
              disabled={hasLengthGreaterThanZero(watch(), "boolean_operation")}
            >
              search
            </Button>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
