import useReactHookForm from "hooks/useReactHookForm";
import { Button, Grid, Paper, Typography } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { useEffect, useState } from "react";
import configueService from "pages/configue/service";
import { useRouteMatch, useHistory } from "react-router-dom";
import {
  createProgramInitialValues,
  createProgramMandatoryFields,
  createProgramProperties,
  createProgramValidationSchema,
  updateProgramProperties,
} from "./helper";
import { getBatchListData } from "utils/metadata-split/metaDataApiFetch";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import { UpdateSingleStdProgramDetails } from "pages/configue/slices/student-program-slice";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import LoadingButton from "@mui/lab/LoadingButton";

export const UpdateStdProgram = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    createProgramInitialValues,
    createProgramValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const match: any = useRouteMatch();
  const history = useHistory();
  const[stepOne,setStepOne]=useState(false);
  const[stepTwo,setStepTwo]=useState(false);
  const [btnLoading,setBtnLoading]=useState(false);
  const { data, updateStudentProgramDetails } = useAppSelector(
    (state) => state.studentProgram
  );

  const { isLoading: isLoad, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );
  const updateMetaData = async (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    await dispatch(metadataSuccess(metadataList));
  };

  const handleClick = async () => {
    setBtnLoading(true);
    if (match?.url?.includes("duplicate")) {
      const reqBody = {
        id: "id", // backend requir id for create
        class_list: watch()?.class_list,
        match_type: watch()?.match_type,
        match_id_list: watch()?.match_id_list,
        program_id: watch()?.program_id,
      };
      const res: any = await configueService.createProgramLookupApi(reqBody);
      if (res?.status === 200 && res?.data?.status === "success") {
        dispatch(snackbarRequest({ message: "created successfully" }));
        setBtnLoading(false);
        history.replace(`/configue/student_program_lookup`);
      } else {
        setBtnLoading(false);
        dispatch(snackbarRequest({ message: res.data.message, type: "error" }));
      }
    } else {
      const reqBody = {
        id: match?.params?.std_program_id,
        class_list: watch()?.class_list,
        match_type: watch()?.match_type,
        match_id_list: watch()?.match_id_list,
        program_id: watch()?.program_id,
      };
      const res: any = await configueService.getUpdateProgramLookupListApi(
        reqBody
      );
      if (res?.status === 200 && res?.data?.status === "success") {
        dispatch(snackbarRequest({ message: "updated successfully" }));
        setBtnLoading(false);
        history.replace(`/configue/student_program_lookup`);
      } else {
        setBtnLoading(false);
        dispatch(snackbarRequest({ message: res.data.message, type: "error" }));
      }
    }
  };

  const takeRequiredValue = (classList, key) => {
    const classNameList = classList?.map((SingleClass) => {
      return SingleClass[`${key}`];
    });
    return classNameList;
  };

  const getStudentProgramValue = async () => {
    try {
      const res: any = await configueService.getSingleProgramLookupListApi({
        lookup_id: match?.params?.std_program_id,
        id: match?.params?.std_program_id,
      });
      if (res?.status === 200 && res?.data?.status === "success") {
        dispatch(UpdateSingleStdProgramDetails(res?.data?.data?.[0]));
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    if (updateStudentProgramDetails?.id) {
      setValue(
        "class_list",
        updateStudentProgramDetails?.class_id_list
      );
      setValue("match_type", updateStudentProgramDetails?.match_type);
      setValue("match_id_list", updateStudentProgramDetails?.match_id_list);
      setValue("program_id", updateStudentProgramDetails?.program_id);
      if (!stepOne) {
        // to handle initial value set when duplicate or edit
        setStepOne(true);
      }
    } else if (data?.length == 0) {
      getStudentProgramValue();
    }
  }, [updateStudentProgramDetails]);

  useEffect(() => {
    setTimeout(async () => {
      if (watch()?.match_type == "batch" && stepOne) {
        const call1 = await getBatchListData("", {});
        if (call1) {
          updateMetaData(call1[0], call1[1]);
        } else {
          updateMetaData([], "batch_list");
        }
      }
    }, 1000);
    if (stepOne) {
      // to handle initial value set when duplicate or edit
      setStepTwo(true);
    }
    if (stepOne && stepTwo) {
      // after initial setup the dependency value set to []
      setValue("match_id_list", []);
    }
  }, [watch()?.match_type,stepOne]);

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {match?.url?.includes("duplicate") ? "duplicate" : "Update"} Program
          Map
        </Typography>
        <Grid container spacing={2}>
          <DynamicForm
            fields={updateProgramProperties(watch())}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
        </Grid>
        <ContentRight sx={{ mt: 1 }}>
          <LoadingButton
            variant="contained"
            loadingPosition="start"
            color="primary"
            onClick={handleClick}
            loading={btnLoading}
            style={{
              width: "180px",
              height: "40px",
              marginTop: "15px",
              padding: "7px 42.5px 7px 48.5px",
              cursor: "pointer",
              textAlign: "center",
            }}
            disabled={
              !isFieldsValidate(errors, createProgramMandatoryFields, getValues)
            }
          >
            {match?.url?.includes("duplicate") ? "Save as New" : "Update"}
          </LoadingButton>
        </ContentRight>
      </Paper>
    </div>
  );
};
