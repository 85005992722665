import request from "utils/axios/service";
import { endpoints } from "./constants";

export default class testStatusService {
  static getDownloadTestStatus(payload) {
    return request.get(endpoints.DOWNLOAD_TEST_STATUS, payload);
  }
  static getDownloadResultComparision(payload) {
    return request.get(endpoints.RESULT_COMPARISION, payload);
  }
}
