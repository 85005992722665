import React from "react";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import { Stack, Tooltip, IconButton, Typography } from "@mui/material";

type ButtonProps = {
  handleDownload?: () => void;
  showDownload: boolean;
};

export const ConditionalDownload = ({ handleDownload, showDownload }: ButtonProps) => {
    
  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
    >
      <Typography variant="body1">
        {showDownload ? "True" : "False"}
      </Typography>
      {showDownload && (
        <Tooltip title="Download">
          <IconButton onClick={handleDownload}>
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};