import { all, fork } from "redux-saga/effects";
import authSaga from "pages/login/saga";
import assignmentsSaga from "pages/assignments/saga";
import monthlyScheduleSaga from "pages/monthly-schedule/saga";
import termExamSaga from "pages/term-exams/saga";
import librarysResourceSaga from "pages/library/saga";
import announcementSaga from "pages/announcements/saga";
import getLearnSaga from "pages/learn/saga";
import buildTest from "pages/build-test/saga";
import activitiessaga from "pages/activities/saga";
import warmUpSaga from "pages/warm-up-delivery/saga";
import hierarchiesSaga from "pages/hierarchies/saga";
import appSaga from "../../app-component/saga";
import configueSaga from "pages/configue/saga";
import TestStatusSaga from "pages/test-status/saga";

const _sagas = [
  appSaga,
  authSaga,
  assignmentsSaga,
  termExamSaga,
  monthlyScheduleSaga,
  librarysResourceSaga,
  announcementSaga,
  getLearnSaga,
  buildTest,
  warmUpSaga,
  activitiessaga,
  hierarchiesSaga,
  configueSaga,
  TestStatusSaga,
];

export default function* () {
  yield all(_sagas.map((saga) => fork(saga)));
}
