import { Button, Grid, Paper, Typography } from "@mui/material";
import useReactHookForm from "hooks/useReactHookForm";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import configueService from "pages/configue/service";
import {
  areArraysEqual,
  hasLengthGreaterThanZero,
  setReactFormValues,
} from "pages/configue/constants";
import {
  createProgramInitialValues,
  createProgramValidationSchema,
  createProgramProperties,
  createProgramMandatoryFields,
} from "./helper";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { isEmpty } from "utils/helpers";

export const CreateProgram = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    createProgramInitialValues,
    createProgramValidationSchema,
    ""
  );

  const [loading,setLoading]=useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleCreateProgram = async () => {
    setLoading(true);
    const namePl = watch()?.name?.trim();
    if (isEmpty(namePl)) {
      dispatch(
        snackbarRequest({ message: "Please check/fill name", type: "error" })
      );
      setLoading(false);
      return;
    }
    const reqBody = {
      name: namePl,
    };
    const res: any = await configueService.createProgramProperties(reqBody);
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(snackbarRequest({ message: "created successfully" }));
      setLoading(false);
      history.replace(`/configue/create_program`);
    } else {
      setLoading(false);
      dispatch(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  };

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Create New Program
        </Typography>
        <Grid container spacing={2}>
          <DynamicForm
            fields={createProgramProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              color="primary"
              onClick={handleCreateProgram}
              loading={loading}
              style={{
                width:"120px"
              }}
              disabled={
                !isFieldsValidate(
                  errors,
                  createProgramMandatoryFields,
                  getValues
                )
              }
            >
              create
            </LoadingButton>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
