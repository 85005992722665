import { Grid, TextField, TextareaAutosize } from "@mui/material";
import { Controller } from "react-hook-form";
import FieldError from "shared/organisms/dynamicform/FieldError";

const TextAreaField = ({ field, control, errors }) => {
  return (
    <Grid item {...field.breakpoints}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <TextareaAutosize
            aria-label="minimum height"
            minRows={5}
            onChange={onChange}
            required={field.required}
            value={value}
            placeholder={field.label}
            style={{
              width: "100%",
              height: "13%",
              resize: "none",
              border: "1px solid #c4c4c4",
              borderRadius: "4px",
              fontSize: "16px",
              padding: "10px 0px 0px 12px",
              backgroundColor: "#ffffff",
              color: field.readOnly ? "#9e9e9eff" : "#262626ff",
              cursor: field.readOnly ? "not-allowed" : "text",
            }}
            className={field.className}
            disabled={field.readOnly ? true : false}
          />
        )}
        name={field.id}
        control={control}
        defaultValue=""
      />
      {errors && errors[field.id]?.message && (
        <FieldError field={field} errors={errors} />
      )}
    </Grid>
  );
};

export default TextAreaField;
