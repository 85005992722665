import { TextField } from "@mui/material";
import { useEffect } from "react";

export const SearchField = ({inputValue, handleSearchChange,setInputValue,flag,setFlag}) => {
  useEffect(()=>{
    setInputValue("");
    setFlag(!flag);
  },[])
  return (
    <TextField
      label="search"
      variant="outlined"
      value={inputValue}
      onChange={handleSearchChange}
      onClick={(e) => e.stopPropagation()}
      fullWidth
      autoFocus
    />
  );
};
