import React, { useEffect, useState } from "react";
import { getEnableFeatures } from "../sidebar/helpers";
import NavItem from "shared/atoms/nav-item";
import NavCollapse from "shared/molecules/nav-collapse";
import List from "@mui/material/List";
import Profile from "../sidebar/profile";
import { useAppSelector } from "hooks/redux";
import { NCMS_ROLES } from "utils/role-restriction/helper";
import { isEmpty } from "utils/helpers";

function Panel(props: any) {
  const { data } = useAppSelector((state) => state.featureConfig);
  const { panelStatus } = props;
   const { profileData } = useAppSelector((state) => state.gmailLoginReducer);
   const {role:userRoleStr}=profileData
   const userRole = userRoleStr?.split(",");
  const features = getEnableFeatures(data);

  const checkAccess = (link) => {
    if (link == "/configue") {
      if (!isEmpty(userRole?.length) && userRole?.includes(NCMS_ROLES.PLATFORM_ADMIN)) {
        return true;
      } else {
        return false;
      }
    }
    else if( link == "/teststatus" ){
      if (!isEmpty(userRole?.length) && userRole?.includes(NCMS_ROLES.TEST_STATUS_ANALAYSER)) {
        return true;
      } else {
        return false;
      }
    }
     else {
      if (!isEmpty(userRole?.length) && userRole?.includes(NCMS_ROLES.CONTENT_ADMIN)) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#0F172B",
        color: "#fff",
        height: "100%",
      }}
    >
      <List component="nav" aria-labelledby="nested-list-subheader">
        <Profile hideUser={true} hideLogo={false} />
        {features.length
          ? features.map((item: any) => {
              if (item.type === "item")
                return <NavItem key={item.name} navItemData={item} canAccess={checkAccess(item?.link)} />;
              return (
                <NavCollapse
                  key={item.name}
                  navItemData={item}
                  panelStatus={panelStatus}
                  canAccess={checkAccess(item?.link)}
                />
              );
            })
          : null}
      </List>
    </div>
  );
}

export default Panel;
