import useReactHookForm from "hooks/useReactHookForm";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { useEffect, useState } from "react";
import configueService from "pages/configue/service";
import { useRouteMatch, useHistory } from "react-router-dom";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import {
  getBatchListData,
  getCollegeListData,
} from "utils/metadata-split/metaDataApiFetch";
import {
  createConfigInitialValues,
  createConfigMandatoryFields,
  createConfigProperties,
  createConfigValidationSchema,
} from "./helper";
import {
  getBatchFromClassId,
  getBatchFromClassIdForMs,
  getSubBatchFromBatchId,
  getSubBatchFromBatchIdForUp,
} from "utils/metadata-split/depedentApiFetch";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import { areArraysEqual, getValuesByIds, META_FIELD_MAP, PC_METADATA_KEYS, removeDuplicates, setReactFormValues } from "pages/configue/constants";
import { UpdatefeatureConfigDetails, updateSelectedCollegeList } from "pages/configue/slices/feature-config-slice";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import LoadingButton from "@mui/lab/LoadingButton";
import { isEmpty } from "utils/helpers";

const initialJsonData = {};

export const UpdateConfig = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    createConfigInitialValues,
    createConfigValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const match: any = useRouteMatch();
  const history = useHistory();
  const [fetchAll, setFetchAll] = useState(true);
  const [triggerSetValue, setTriggerSetValue] = useState(false);
  const [jsonData, setJsonData] = useState(initialJsonData);
  const [btnLoading,setBtnLoading]=useState(false);
  const [initialCall,setinitialCall]=useState(false);
  const { data, updateConfigDetails } = useAppSelector(
    (state) => state.featureConfigApiSlice
  );

  const { isLoading: isLoad, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );

  const handleJsonChange = (e) => {
    if (e.jsObject) {
      setJsonData(e.jsObject);
    }
  };

  const handleClick = async () => {
    setBtnLoading(true);
    if (match?.url?.includes("duplicate")) {
      const reqBody = {
        id: "id",
        academic_year_list: watch()?.academic_year_list,
        registration_status_list: watch()?.reservation_status_list,
        class_list: watch()?.class_list,
        batch_list: watch()?.batch_list,
        sub_batch_list: watch()?.sub_batch_list,
        college_list: watch()?.college_list,
        platform_list: watch()?.platform_list,
        is_global_config: watch()?.is_global_config == "true" ? true : false,
        is_early_bird_config:
          watch()?.is_early_bird_config == "true" ? true : false,
        config_json: jsonData,
      };
      const res: any = await configueService.createConfigApi(reqBody);
      if (res?.status === 200 && res?.data?.status === "success") {
        dispatch(snackbarRequest({ message: "created successfully" }));
        setBtnLoading(false);
        history.replace(`/configue/feature_configue`);
      } else {
        setBtnLoading(false);
        dispatch(snackbarRequest({ message: res.data.message, type: "error" }));
      }
    } else {
      const reqBody = {
        id: match?.params?.config_id,
        academic_year_list: watch()?.academic_year_list,
        registration_status_list: watch()?.reservation_status_list,
        class_list: watch()?.class_list,
        batch_list: watch()?.batch_list,
        sub_batch_list: watch()?.sub_batch_list,
        college_list: watch()?.college_list,
        platform_list: watch()?.platform_list,
        is_global_config: watch()?.is_global_config == "true" ? true : false,
        is_early_bird_config:
          watch()?.is_early_bird_config == "true" ? true : false,
        config_json: jsonData,
      };
      const res: any = await configueService.updateConfigApi(reqBody);
      if (res?.status === 200 && res?.data?.status === "success") {
        dispatch(snackbarRequest({ message: "updated successfully" }));
        setBtnLoading(false);
        history.replace(`/configue/feature_configue`);
      } else {
        setBtnLoading(false);
        dispatch(snackbarRequest({ message: res.data.message, type: "error" }));
      }
    }
  };

  const takeRequiredValue = (arr, key) => {
    const modArr = arr?.map((obj) => {
      return obj[`${key}`];
    });
    if (modArr?.includes(undefined) || modArr?.includes(null)) {
      return [];
    } else {
      return modArr;
    }
  };

  const getSingleConfigueValue = async () => {
    try {
      const res: any = await configueService.getSingleConfigListApi({
        config_id: match?.params?.config_id,
      });
      if (res?.status === 200 && res?.data?.status === "success") {
        dispatch(UpdatefeatureConfigDetails(res?.data?.data?.[0]));
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const giveRequiredObjs = (collegeList) => {
    if (isEmpty(collegeList)) return [];
    const labelsArray = collegeList?.map((collegeId: string) =>
      metaData?.[`${PC_METADATA_KEYS?.COLLEGE_LIST}`].find((college) => {
        return college.college_id === collegeId;
      })
    );
    return labelsArray || [];
  };
  const convertToLabelValueFormat = ( data) => {
    if (!data || data.length === 0) return [];
    const convertOts= data.map((item: any) => ({
      label: item["college_name"],
      value: item["college_id"],
    }));
    return convertOts
  };

  useEffect(() => {
    if (updateConfigDetails?.id) {
      setValue(
        "class_list",
        updateConfigDetails?.class_id_list
      );
      setValue(
        "academic_year_list",  updateConfigDetails?.year_id_list
      );
      setValue(
        "batch_list",
        updateConfigDetails?.batch_id_list
      );
      setValue(
        "college_list",updateConfigDetails?.college_id_list
      );
      setValue(
        "reservation_status_list",
          updateConfigDetails?.registration_status_id_list
      );
      setValue(
        "sub_batch_list",
        updateConfigDetails?.sub_batch_id_list
      );
      setValue(
        "is_global_config",
        updateConfigDetails?.is_global_config == true ? "true" : "false"
      );
      setValue(
        "is_early_bird_config",
        updateConfigDetails?.is_early_bird_config == true ? "true" : "false"
      );
      setValue("platform_list", updateConfigDetails?.platform_list);
      setJsonData(updateConfigDetails?.config_json);
      dispatch(
        updateSelectedCollegeList({
          key: "createCollgeList",
          value: convertToLabelValueFormat( giveRequiredObjs(updateConfigDetails?.college_id_list)),
        })
      );
    } else if (data?.length == 0) {
      getSingleConfigueValue();
    }
  }, [updateConfigDetails]);

  const updateMetaData = async (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    await dispatch(metadataSuccess(metadataList));
    if (metaKey == "sub_batch_list" && listData?.length == 0) {
      setValue("sub_batch_list", []);
    }
  };

  const updateMetaDataMs = async (
    listData,
    metaKey,
    listData2,
    metaKey2,
  ) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    metadataList[metaKey2] = listData2;
    await dispatch(metadataSuccess(metadataList));
    setFetchAll(false);
  };

  const updateClassIdDep = async () => {
    let metadataList = { ...metaData };
    metadataList["batch_list"] = [];
    metadataList["sub_batch_list"] = [];
    await dispatch(metadataSuccess(metadataList));
    setValue("batch_list", []);
    setValue("sub_batch_list", []);
  };

  useEffect(() => {
    //aplist
    if (
      fetchAll &&
      watch()?.class_list?.length > 0 &&
      //watch()?.batch_list?.length > 0 && !initialCall
      !initialCall
    ) {
      (async () => {
       // const call1: any = await getCollegeListData("", {});
       setinitialCall(true);
        const call1 = await getBatchFromClassIdForMs(
          watch()?.class_list,
          updateMetaDataMs
        );
        const call2 = await getSubBatchFromBatchIdForUp(
          watch()?.batch_list,
          updateMetaDataMs
        );
          updateMetaDataMs(
            call1[0],
            call1[1],
            call2[0],
            call2[1],
          );
      })();
    }
  }, [watch()?.class_list]);

  useEffect(() => {
    if (!fetchAll) {
      if (watch()?.class_list?.length > 0) {
        setTimeout(() => {
          getBatchFromClassId(watch()?.class_list, updateMetaData);
        }, 1000);
      } else {
        updateClassIdDep();
      }
    }
  }, [watch()?.class_id]);

  useEffect(() => {
    if (!fetchAll) {
      setTimeout(() => {
        getSubBatchFromBatchId(watch()?.batch_list, updateMetaData);
      }, 1000);
    }
  }, [watch()?.batch_list]);

  useEffect(() => {
    if (metaData?.batch_list?.length > 0 && !isLoad && triggerSetValue) {
      let matchedVales = setReactFormValues(
        metaData,
        "batch_list",
        watch()?.batch_list,
        "batch_id"
      );
      let isEqual = areArraysEqual(watch()?.batch_list, matchedVales);
      if (!isEqual) {
        setValue("batch_list", matchedVales);
      }
    }
  }, [metaData?.batch_list, triggerSetValue]);

  useEffect(() => {
    if (metaData?.sub_batch_list?.length > 0 && !isLoad && triggerSetValue) {
      let matchedVales = setReactFormValues(
        metaData,
        "sub_batch_list",
        watch()?.sub_batch_list,
        "sub_batch_id"
      );
      let isEqual = areArraysEqual(watch()?.sub_batch_list, matchedVales);
      if (!isEqual) {
        setValue("sub_batch_list", matchedVales);
      }
    }
  }, [metaData?.sub_batch_list]);

  useEffect(() => {
    if (watch()?.is_global_config == "true") {
      setValue("is_early_bird_config", "false");
    }
  }, [watch()?.is_global_config]);

  useEffect(() => {
    setTimeout(() => {
      setTriggerSetValue(true);
    }, 3000);
    return () => {
      dispatch(updateSelectedCollegeList({ key:"createCollgeList" , value: [] }));
    };
  }, []);

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {match?.url?.includes("duplicate") ? "duplicate" : "Update"} configue
        </Typography>
        <Grid container spacing={2}>
          <DynamicForm
            fields={createConfigProperties(watch())}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
        </Grid>
        <ContentRight sx={{ mt: 1 }}>
          <LoadingButton
            variant="contained"
            loadingPosition="start"
            color="primary"
            onClick={handleClick}
            loading={btnLoading}
            style={{
              width: "180px",
              height: "40px",
              marginTop: "15px",
              padding: "7px 42.5px 7px 48.5px",
              cursor: "pointer",
              textAlign: "center",
            }}
            disabled={
              !isFieldsValidate(errors, createConfigMandatoryFields, getValues)
            }
          >
            {match?.url?.includes("duplicate") ? "Save as New" : "Update"}
          </LoadingButton>
        </ContentRight>
        <Box sx={{ padding: "20px" }}>
          <JSONInput
            id="json-editor"
            placeholder={jsonData}
            locale={locale}
            height="550px"
            width="800px"
            onChange={handleJsonChange}
            style={{
              outerBox: { fontSize: "40px" },
              container: { fontSize: "40px" },
              text: { fontSize: "40px" },
            }}
          />
        </Box>
      </Paper>
    </div>
  );
};
