import request from "utils/axios/service";
import { endpoints } from "./constants";

export default class configueService {
  static getAppVersionListApi(payload) {
    return request.get(endpoints.GET_APP_VERSION_LIST, payload);
  }
  static getSingleAppVersionListApi(payload) {
    return request.get(endpoints.GET_SINGLE_VERSION_LIST, payload);
  }

  static updateAppVersionApi(payload: any) {
    return request.post(endpoints.UPDATE_APP_VERSION, payload);
  }

  static getCourseLookupListApi(payload) {
    return request.get(endpoints.GET_COURSE_LOOKUP, payload);
  }
  static getSingleCourseLookup(payload) {
    return request.get(endpoints.GET_SINGLE_COURSE_LOOKUP, payload);
  }
  static createCourseLookupApi(payload: any) {
    return request.post(endpoints.CREATE_COURSE_LOOKUP, payload);
  }
  static updateCourseLookupApi(payload: any) {
    return request.post(endpoints.UPDATE_COURSE_LOOKUP, payload);
  }

  static getProgramLookupListApi(payload) {
    return request.get(endpoints.GET_PROGRAM_LOOKUP, payload);
  }
  static getSingleProgramLookupListApi(payload) {
    return request.get(endpoints.GET_SINGLE_PROGRAM_LOOKUP, payload);
  }
  static getUpdateProgramLookupListApi(payload) {
    return request.post(endpoints.GET_UPDATE_PROGRAM_LOOKUP, payload);
  }
  static createProgramLookupApi(payload: any) {
    return request.post(endpoints.CREATE_PROGRAM_LOOKUP, payload);
  }

  static getConfigListApi(payload) {
    return request.get(endpoints.GET_CONFIG, payload);
  }
  static getSingleConfigListApi(payload) {
    return request.get(endpoints.GET_SINGLE_CONFIG, payload);
  }
  static updateConfigApi(payload: any) {
    return request.post(endpoints.UPDATE_CONFIG, payload);
  }
  static createConfigApi(payload: any) {
    return request.post(endpoints.CREATE_CONFIG, payload);
  }

  static deleteCourseLookup(payload: any) {
    return request.post(endpoints.DELETE_COURSE_LOOKUP, payload);
  }

  static deleteProgramLookup(payload: any) {
    return request.post(endpoints.DELETE_PROGRAM_LOOKUP, payload);
  }
  static deleteConfigue(payload: any) {
    return request.post(endpoints.DELETE_CONFIG, payload);
  }

  static createCourseProperties(payload: any) {
    return request.post(endpoints.CREATE_COURSE_PROPERTIES, payload);
  }
  static updateCourseProperties(payload: any) {
    return request.post(endpoints.UPDATE_COURSE_PROPERTIES, payload);
  }
  static getCourseListApi(payload) {
    return request.get(endpoints.GET_COURSE_PROPERTIES, payload);
  }

  static createProgramProperties(payload: any) {
    return request.post(endpoints.CREATE_PROGRAM_PROPERTIES, payload);
  }
  static updateProgramProperties(payload: any) {
    return request.post(endpoints.UPDATE_PROGRAM_PROPERTIES, payload);
  }
  static getProgramListApi(payload) {
    return request.get(endpoints.GET_PROGRAM_PROPERTIES, payload);
  }
}
