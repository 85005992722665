import { Switch, Route } from "react-router-dom";
import { ForceUpdate } from "./routes/force-update";
import { UpdateVersion } from "./routes/force-update/UpdateVersion";
import { StudentCourseLookUp } from "./routes/student-Course-lookup";
import { CreateStdCourse } from "./routes/student-Course-lookup/CreateStudentCourse";
import { FeatureConfigue } from "./routes/feature-configue";
import { CreateConfig } from "./routes/feature-configue/CreateConfig";
import { StudentProgramLookUp } from "./routes/student-program-lookup";
import { CreateStdProgram } from "./routes/student-program-lookup/CreateStudentProgram";
import { UpdateStdProgram } from "./routes/student-program-lookup/UpdateStudentProgram";
import { UpdateConfig } from "./routes/feature-configue/UpdateConfig";
import { UpdateStdCourse } from "./routes/student-Course-lookup/UpdateStudentCourse";
import { CreateCourseMain } from "./routes/create-course";
import { CreateProgramMain } from "./routes/create-program";
import { CreateCourse } from "./routes/create-course/create";
import { UpdateCourse } from "./routes/create-course/update";
import { CreateProgram } from "./routes/create-program/create";
import { UpdateProgram } from "./routes/create-program/update";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { platformConfigueRoutes } from "./routes";
import { checkFeatureAccess } from "utils/role-restriction/helper";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { useEffect, useState } from "react";
import { getBatchListDataForPC, getCollegeListDataForPC, getProgramListDataForPC, getSubBatchListDataForPC } from "utils/metadata-split/metaDataApiFetch";

export function ConfiguePage({ match }: { match: any }) {
  const { profileData } = useAppSelector((state) => state.gmailLoginReducer);
  const { role: userRoleStr } = profileData;
  const userRole = userRoleStr?.split(",");
  const dispatch = useAppDispatch();
  const { isLoading,data:metaData }: any = useAppSelector((state) => state.metadataReducer);

  const[showLoading,setShowIsLoading]=useState(true);

  const updateMetaData=(listData,metaKey,listData2,metaKey2,listData3,metaKey3,listData4,metaKey4)=>{
    let metadataList = {...metaData};
    metadataList[metaKey] = listData;
    metadataList[metaKey2] = listData2;
    metadataList[metaKey3] = listData3;
    metadataList[metaKey4] = listData4;
   dispatch(metadataSuccess(metadataList))
     setShowIsLoading(false);
  }

  useEffect(()=>{
    (async()=>{
      try{
       const call1:any= await getCollegeListDataForPC(updateMetaData);
       const call2:any= await   getBatchListDataForPC(updateMetaData);
       const call3:any= await   getSubBatchListDataForPC(updateMetaData);
       const call4:any= await   getProgramListDataForPC(updateMetaData);
       updateMetaData(call1[0],call1[1],call2[0],call2[1],call3[0],call3[1],call4[0],call4[1])
      }
      catch(error){
        console.log(error)
      }
   })()
  },[])

  if(showLoading){
    return <p>loading....</p>
  }

  return (
    <>
      <Switch>
        {platformConfigueRoutes.map((route, i) => {
          return (
            checkFeatureAccess(userRole, route?.user) && (
              <Route
                key={i}
                exact
                path={`${match.url}${route.path}`}
                component={route.component}
              />
            )
          );
        })}
      </Switch>
    </>
  );
}
