import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitState {
  loading: boolean;
  data: any;
  error: string;
  successMsg: any;
  updateConfigDetails: any;
  filtersData: any;
  selectedCollegeList:any;
}

const initialState = {
  loading: false,
  data: [],
  error: "",
  successMsg: {},
  updateConfigDetails: {},
  filtersData: {},
  selectedCollegeList: {
    searchCollegeList: [],
    createCollgeList: [],
  },
};

const featureConfigApiSlice = createSlice({
  name: "featureConfigApi",
  initialState,
  reducers: {
    featureConfigRequest(state: IInitState, action: PayloadAction<any>) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    featureConfigSuccess(state: IInitState, action: PayloadAction<any[]>) {
      state.loading = false;
      state.data = action.payload;
    },
    UpdatefeatureConfigDetails(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.updateConfigDetails = action.payload;
    },
    emptyConfigData(state: IInitState) {
      state.data = [];
      state.loading = false;
    },
    updateSelectedCollegeList(state:IInitState,action:PayloadAction<any>){
      const {key,value}=action?.payload
      state.selectedCollegeList[`${key}`]=value;
    }
  },
});

export const {
  featureConfigRequest,
  featureConfigSuccess,
  UpdatefeatureConfigDetails,
  emptyConfigData,
  updateSelectedCollegeList,
} = featureConfigApiSlice.actions;
export default featureConfigApiSlice.reducer;
