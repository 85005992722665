import * as Yup from "yup";
export type createStdCourseType = {
  academic_year_list: string[];
  reservation_status_list: string[];
  class_list: string[];
  batch_list: string[];
  state_list: string[];
  college_list: string[];
  board_list: string[];
  course_id: string;
};

export const createStdCourseInitialValues: createStdCourseType = {
  academic_year_list: [],
  reservation_status_list: [],
  class_list: [],
  batch_list: [],
  state_list: [],
  college_list: [],
  board_list: [],
  course_id: "",
};

export const createStdCourseMandatoryFields = [
  { id: "academic_year_list" },
  { id: "reservation_status_list" },
  { id: "class_list" },
  { id: "state_list" },
  { id: "college_list" },
  { id: "board_list" },
  { id: "course_id" },
];

export const createStdCourseValidationSchema = Yup.object().shape({
  // academic_year_list: Yup.array()
  //   .required("value required")
  //   .min(1, "value required"),
  // reservation_status_list: Yup.array()
  //   .min(1, "value required")
  //   .required("value required"),
  // // reservation_status_id:Yup.array().of(Yup.string()).required("value required"),
  // class_list: Yup.array().min(1, "value required").required("value required"),
  // batch_list: Yup.array().min(1, "value required").required("value required"),
  // state_list: Yup.array().min(1, "value required").required("value required"),
  // college_list: Yup.array().min(1, "value required").required("value required"),
  // board_list: Yup.array().min(1, "value required").required("value required"),
  // course_id: Yup.string().required("value required"),
});

export const createStudentCourseProperties = [
  {
    id: "academic_year_list",
    type: "multi-select",
    label: "Academic Year ",
    className: "select-styles",
    required: true,
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 2.1 },
    options: [],
    list: "academic_year_list",
    keyValue: { label: "year_name", value: "year_id" },
  },
  {
    id: "reservation_status_list",
    type: "multi-select",
    label: "Registration Status ",
    required: true,
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 2.1 },
    options: [],
    list: "reservation_status_list",
    keyValue: {
      label: "registration_status_name",
      value: "registration_status_id",
    },
  },
  {
    id: "class_list",
    type: "multi-select-dependency",
    label: "Class",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 2 },
    options: [],
    required: true,
    list: "class_course_batch_data",
    keyValue: { label: "class_name", value: "class_id" },
    dependentFields: ["batch_list","course_id"],
    dependentFlds: {
      class_course_batch_data: "class_list",
    },
  },
  {
    id: "batch_list",
    type: "multi-select-dependency",
    label: "Batch",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 1.8 },
    isDependent: true,
    options: [],
    required: true,
    list: "batch_list",
    dependencyLabel: "Please select Class",
    keyValue: { label: "batch_name", value: "batch_id" },
    fieldKeyLists: {},
    dependentFields: [],
    dependentFlds: {
      batch_list: "batch_list",
    },
  },
  {
    id: "state_list",
    type: "multi-select-dependency",
    label: "State",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 1.9 },
    options: [],
    list: "state_list",
    keyValue: { label: "state_name", value: "state_id" },
    dependentFields: ["college_list"],
    dependentFlds: {
      state_list: "state_list",
      //college_list: "college_id",
    },
  },
  {
    id: "college_list",
    type: "multi-select-dependency",
    label: "College",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 2.1 },
    isDependent: true,
    options: [],
    list: "college_list",
    dependencyLabel: "Please select State",
    keyValue: { label: "college_name", value: "college_id" },
    fieldKeyLists: {},
  },
  {
    id: "board_list",
    type: "multi-select-dependency",
    label: "Board",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 2.1 },
    options: [],
    list: "board_list",
    keyValue: { label: "board_name", value: "board_id" },
  },
  // {
  //   id: "course_id",
  //   type: "single-select-dependency",
  //   label: "Course",
  //   className: "select-styles",
  //   breakpoints: { xs: 12, sm: 6, md: 4, lg: 2.1 },
  //   required: true,
  //   // isDependent: true,
  //   options: [],
  //   list: "course_list",
  //   keyValue: { label: "course_name", value: "course_id" },
  //   fieldKeyLists: {},
  //   dependentFields: [],
  //   enableSearch:true
  // },
  {
    id: "course_id",
    type: "single-select-dependency-search",
    label: "Course",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 2.1 },
    required: true,
    isDependent: true,
    options: [],
    list: "course_list_dep",
    dependencyLabel: "Please select Class",
    keyValue: { label: "course_name", value: "course_id" },
    fieldKeyLists: {},
    dependentFields: [],
    enableSearch:true
  },
  {
    id: "boolean_operation",
    type: "normal-select",
    label: "Filter Operation",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 2 },
    options: [
      { label: "AND", value: "AND" },
      { label: "OR", value: "OR" },
    ],
  },
];
