import { Switch, Route } from "react-router-dom";
import { TestStatusMain } from "./routes/test-status";

export function TestStatus({ match }: { match: any }) {
  return (
    <>
      <Switch>
        <Route exact path={`${match.url}`} component={TestStatusMain} />
      </Switch>
    </>
  );
}
