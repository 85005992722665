import { endpointsMd } from "utils/metadata-split/constant";
import * as Yup from "yup";

export type createProgramType = {
  class_list: string[];
  match_type: string;
  match_id_list: string[];
  program_id: string;
  boolean_operation: string;
};

export const createProgramInitialValues: createProgramType = {
  class_list: [],
  match_type: "",
  match_id_list: [],
  program_id: "",
  boolean_operation: "",
};

export const createProgramMandatoryFields = [
  { id: "class_list" },
  { id: "match_type" },
  { id: "match_id_list" },
  { id: "program_id" },
  { id: "boolean_operation" },
];

export const createProgramValidationSchema = Yup.object().shape({
  // class_list: Yup.array().min(1, "value required").required("value required"),
  // match_id_list: Yup.array()
  //   .min(1, "value required")
  //   .required("value required"),
  // match_type: Yup.string().required("value required"),
  // program_id: Yup.string().required("value required"),
  // boolean_operation: Yup.string().required("value required"),
});

export const createProgramProperties = (watch: any) => {
  return [
    {
      id: "class_list",
      type: "multi-select-dependency",
      label: "Class",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 2.6 },
      options: [],
      required: true,
      list: "class_course_batch_data",
      keyValue: { label: "class_name", value: "class_id" },
      dependentFields: ["batch_list", "sub_batch_list"],
      dependentFlds: {
        class_course_batch_data: "class_id",
      },
    },
    {
      id: "program_id",
      type: "normal-select",
      label: "Program",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 2.6 },
      options: [],
      required: true,
      isDependent: false,
      list: "program_class_data",
      onlyApiCall: true,
      requestList: async (payload) => {
        return {};
      },
      endpoint: endpointsMd.GET_PROGRAM_LIST,
      keyValue: { label: "program_name", value: "program_id" },
      //dependentFields: ["class_id", "subject_id"],
      dependentFields: [""],
    },
    {
      id: "match_type",
      type: "normal-select",
      label: "Match Type",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 3, lg: 2.1 },
      options: [
        { label: "Board", value: "board" },
        { label: "Batch", value: "batch" },
      ],
    },
    {
      id: "match_id_list",
      type: "multi-select-dependency",
      label: "Match Id List",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 2.5 },
      isDependent: true,
      options: [],
      list: `${watch?.match_type == "" ? "empty" : watch?.match_type}_list`,
      dependencyLabel: "Please select match_type",
      keyValue:
        watch?.match_type == "board"
          ? { label: "board_name", value: "board_id" }
          : { label: "batch_name", value: "batch_id" },
      fieldKeyLists: {},
    },
    {
      id: "boolean_operation",
      type: "normal-select",
      label: "Filter Operation",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 3, lg: 2.2 },
      options: [
        { label: "AND", value: "AND" },
        { label: "OR", value: "OR" },
      ],
    },
  ];
};
