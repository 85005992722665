import { isEmpty } from "utils/helpers";

export const microServices = {
  NCMSV2: "ncms/v2",
};

export const endpoints = {
  GET_APP_VERSION_LIST: `${microServices.NCMSV2}/app-update/get-app-versions`,
  GET_SINGLE_VERSION_LIST: `${microServices.NCMSV2}/app-update/get-app-version-details`,
  UPDATE_APP_VERSION: `${microServices.NCMSV2}/app-update/update-app-versions`,
  GET_COURSE_LOOKUP: `${microServices.NCMSV2}/course-lookup/get-lookups`,
  GET_SINGLE_COURSE_LOOKUP: `${microServices.NCMSV2}/course-lookup/get-lookup-details`,
  CREATE_COURSE_LOOKUP: `${microServices.NCMSV2}/course-lookup/create-lookup`,
  UPDATE_COURSE_LOOKUP: `${microServices.NCMSV2}/course-lookup/update-lookup`,
  DELETE_COURSE_LOOKUP: `${microServices.NCMSV2}/course-lookup/delete-lookup`,
  GET_PROGRAM_LOOKUP: `${microServices.NCMSV2}/program-lookup/get-lookups`,
  GET_UPDATE_PROGRAM_LOOKUP: `${microServices.NCMSV2}/program-lookup/update-lookup`,
  DELETE_PROGRAM_LOOKUP: `${microServices.NCMSV2}/program-lookup/delete-lookup`,
  GET_SINGLE_PROGRAM_LOOKUP: `${microServices.NCMSV2}/program-lookup/get-lookup-details`,
  CREATE_PROGRAM_LOOKUP: `${microServices.NCMSV2}/program-lookup/create-lookup`,
  GET_CONFIG: `${microServices.NCMSV2}/feature-config/get-config`,
  GET_SINGLE_CONFIG: `${microServices.NCMSV2}/feature-config/get-config-details`,
  CREATE_CONFIG: `${microServices.NCMSV2}/feature-config/create-config`,
  UPDATE_CONFIG: `${microServices.NCMSV2}/feature-config/update-config`,
  DELETE_CONFIG: `${microServices.NCMSV2}/feature-config/delete-config`,

  CREATE_COURSE_PROPERTIES: `${microServices.NCMSV2}/course-lookup/create-course-properties`,
  UPDATE_COURSE_PROPERTIES: `${microServices.NCMSV2}/course-lookup/update-course-properties`,
  GET_COURSE_PROPERTIES: `${microServices.NCMSV2}/course-lookup/get-course-details`,

  CREATE_PROGRAM_PROPERTIES: `${microServices.NCMSV2}/program-lookup/create-program-properties`,
  UPDATE_PROGRAM_PROPERTIES: `${microServices.NCMSV2}/program-lookup/update-program-properties`,
  GET_PROGRAM_PROPERTIES: `${microServices.NCMSV2}/program-lookup/get-program-details`,
};

// helpers

export const setReactFormValues = (metaData, metaValue, value, keyValue) => {
  let valueArray = metaData?.[`${metaValue}`]?.map((object, i) => {
    return object[`${keyValue}`];
  });

  let matchedValues;
  if (Array.isArray(value)) {
    matchedValues = valueArray.filter((val) => value.includes(val));
  } else {
    matchedValues = valueArray.filter((val) => val === value);
  }

  return matchedValues;
};

export const areArraysEqual = (arr1, array2) => {
  let array1: any = [];
  if (typeof arr1 == "string") {
    array1.push(arr1);
  } else {
    array1 = [...arr1];
  }

  if (array1.length !== array2.length) {
    return false;
  }

  const sortedArray1 = array1.slice().sort();
  const sortedArray2 = array2.slice().sort();

  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false;
    }
  }

  return true;
};

export function hasLengthGreaterThanZero(obj, exceptKey) {
  for (let key in obj) {
    if (key !== exceptKey && obj[key]?.length > 0) {
      return false;
    }
  }
  return true;
}

export function checkObjectValues(obj: any, array:string[]): boolean {
  for (const key in obj) {
    if (array.includes(key)) {
      const value = obj[key];
      if (isEmpty(value)) {
        return false; 
      }
    }
  }
  return true; 
}


export function getValuesByIds(idsArray, idArrayKey, idArrayName, metaKey, metadata) {
  // Extract the array of objects from metadata using the provided metaKey
  const itemList = metadata[metaKey];

  // If idsArray is a string, treat it as a single ID
  if (typeof idsArray === 'string' && !isEmpty(idsArray)) {
    const filteredItem = itemList.find(item => item[idArrayKey] === idsArray);
    return filteredItem ? filteredItem[idArrayName] : null;
  }

  // If idsArray is an array, proceed with filtering and mapping
  if (Array.isArray(idsArray) && !isEmpty(idsArray)) {
    return itemList
      .filter(item => idsArray.includes(item[idArrayKey]))
      .map(item => item[idArrayName]);
  }

  return "-";
}

export const META_FIELD_MAP = {
  CLASS_ID: "class_id",
  CLASS_NAME: "class_name",
  COURSE_ID: "course_id",
  COURSE_NAME: "course_name",
  REGISTRATION_STATUS_ID: "registration_status_id",
  REGISTRATION_STATUS_NAME: "registration_status_name",
  ACADEMIC_YEAR_ID: "year_id",
  ACADEMIC_YEAR_NAME: "year_name",
  STATE_ID: "state_id",
  STATE_NAME: "state_name",
  BOARD_ID: "board_id",
  BOARD_NAME: "board_name",
  BATCH_ID: "batch_id",
  BATCH_NAME: "batch_name",
  COLLEGE_ID: "college_id",
  COLLEGE_NAME: "college_name",
  SUB_BATCH_ID: "sub_batch_id",
  SUB_BATCH_NAME: "sub_batch_name",
  PROGRAM_ID: "program_id",
  PROGRAM_NAME: "program_name"
};

export const PC_METADATA_KEYS = {
  CLASS_LIST: "class_course_batch_data",
  COURSE_LIST: "course_list",
  REGISTRATION_STATUS_LIST: "reservation_status_list",
  ACADEMIC_YEAR_LIST: "academic_year_list",
  STATE_LIST: "state_list",
  BOARD_LIST: "board_list",
  BATCH_LIST: "pc_batch_list",
  COLLEGE_LIST: "pc_college_list",
  SUB_BATCH_LIST: "pc_sub_batch_list",
  PROGRAM_LIST: "program_class_data",
};

export function removeDuplicates(arr: string[]): string[] {
  if(!isEmpty(arr)){
    return Array.from(new Set(arr));
  }else{
     return [];
  }
}