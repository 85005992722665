import useReactHookForm from "hooks/useReactHookForm";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { useEffect, useState } from "react";
import configueService from "pages/configue/service";
import { useRouteMatch, useHistory } from "react-router-dom";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import {
  getBatchListData,
  getCollegeListData,
} from "utils/metadata-split/metaDataApiFetch";
import {
  getBatchFromClassId,
  getBatchFromClassIdForMs,
  getBatchFromClassIdReturn,
  getCollegeFromStateId,
  getCollegeFromStateIdForUp,
  getCourseFromClassIdReturn,
  getSubBatchFromBatchId,
  getSubBatchFromBatchIdForUp,
} from "utils/metadata-split/depedentApiFetch";
import {
  createStdCourseInitialValues,
  createStdCourseMandatoryFields,
  createStdCourseValidationSchema,
  isCourseIdInMetadata,
  updateStudentCourseProperties,
} from "./helper";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import { areArraysEqual, setReactFormValues } from "pages/configue/constants";
import { UpdateSingleStdCourseDetails } from "pages/configue/slices/student-course-slice";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import LoadingButton from "@mui/lab/LoadingButton";
import { isEmpty } from "utils/helpers";

export const UpdateStdCourse = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    createStdCourseInitialValues,
    createStdCourseValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const match: any = useRouteMatch();
  const history = useHistory();
  const [fetchAll, setFetchAll] = useState(true);
  const [triggerSetValue, setTriggerSetValue] = useState(false);
  const [btnLoading,setBtnLoading]=useState(false);
  const [initialCall,setinitialCall]=useState(false);
  const { data, updateStudentCorseDetails } = useAppSelector(
    (state) => state.studentCourseLookup
  );

  const { isLoading: isLoad, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );

  const handleClick = async () => {
    setBtnLoading(true);
    if (match?.url?.includes("duplicate")) {
      const reqBody = {
        id: "id",
        academic_year_list: watch()?.academic_year_list,
        registration_status_list: watch()?.reservation_status_list,
        class_list: watch()?.class_list,
        batch_list: watch()?.batch_list,
        board_list: watch()?.board_list,
        college_list: watch()?.college_list,
        state_list: watch()?.state_list,
        course_id: watch()?.course_id,
        is_secondary_course:
          watch()?.is_secondary_course == "true" ? true : false,
        is_default_course: watch()?.is_default_course == "true" ? true : false,
        is_early_bird_course:
          watch()?.is_early_bird_course == "true" ? true : false,
      };
      const res: any = await configueService.createCourseLookupApi(reqBody);
      if (res?.status === 200 && res?.data?.status === "success") {
        dispatch(snackbarRequest({ message: "created successfully" }));
        setBtnLoading(false);
        history.replace(`/configue/student_course_lookup`);
      } else {
        setBtnLoading(false);
        dispatch(snackbarRequest({ message: res.data.message, type: "error" }));
      }
    } else {
      const reqBody = {
        id: match?.params?.course_id,
        academic_year_list: watch()?.academic_year_list,
        registration_status_list: watch()?.reservation_status_list,
        class_list: watch()?.class_list,
        batch_list: watch()?.batch_list,
        board_list: watch()?.board_list,
        college_list: watch()?.college_list,
        state_list: watch()?.state_list,
        course_id: watch()?.course_id,
        is_secondary_course:
          watch()?.is_secondary_course == "true" ? true : false,
        is_default_course: watch()?.is_default_course == "true" ? true : false,
        is_early_bird_course:
          watch()?.is_early_bird_course == "true" ? true : false,
      };
      const res: any = await configueService.updateCourseLookupApi(reqBody);
      if (res?.status === 200 && res?.data?.status === "success") {
        dispatch(snackbarRequest({ message: "updated successfully" }));
        setBtnLoading(false);
        history.replace(`/configue/student_course_lookup`);
      } else {
        setBtnLoading(false);
        dispatch(snackbarRequest({ message: res.data.message, type: "error" }));
      }
    }
  };

  const takeRequiredValue = (arr, key) => {
    const modArr = arr?.map((obj) => {
      return obj[`${key}`];
    });
    if (modArr?.includes(undefined) || modArr?.includes(null)) {
      return [];
    } else {
      return modArr;
    }
  };

  const getStudentCourseValue = async () => {
    try {
      const res: any = await configueService.getSingleCourseLookup({
        lookup_id: match?.params?.course_id,
      });
      if (res?.status === 200 && res?.data?.status === "success") {
        dispatch(UpdateSingleStdCourseDetails(res?.data?.data?.[0]));
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    if (updateStudentCorseDetails?.id) {
      setValue(
        "class_list",
        updateStudentCorseDetails?.class_id_list
      );
      setValue(
        "academic_year_list",
          updateStudentCorseDetails?.year_id_list
      );
      setValue(
        "batch_list",updateStudentCorseDetails?.batch_id_list
      );
      setValue(
        "board_list",
        updateStudentCorseDetails?.board_id_list
      );
      setValue(
        "college_list",
        updateStudentCorseDetails?.college_id_list
      );
      setValue(
        "state_list",
        updateStudentCorseDetails?.state_id_list
      );
      setValue(
        "reservation_status_list",
          updateStudentCorseDetails?.registration_status_id_list  
      );
      setValue(
        "is_secondary_course",
        updateStudentCorseDetails?.is_secondary_course == true
          ? "true"
          : "false"
      );
      setValue(
        "is_default_course",
        updateStudentCorseDetails?.is_default_course == true ? "true" : "false"
      );
      setValue(
        "is_early_bird_course",
        updateStudentCorseDetails?.is_early_bird_course == true
          ? "true"
          : "false"
      );
      setValue("course_id", updateStudentCorseDetails?.course_id);
    } else if (data?.length == 0) {
      getStudentCourseValue();
    }
  }, [updateStudentCorseDetails]);

  const updateMetaData = async (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    await dispatch(metadataSuccess(metadataList));
    if (metaKey == "sub_batch_list" && listData?.length == 0) {
      setValue("sub_batch_list", []);
    }
    if (metaKey == "college_list" && listData?.length == 0) {
      setValue("college_list", []);
    }
  };

  const updateMetaDataMs = async (listData, metaKey, listData2, metaKey2,listData3, metaKey3) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    metadataList[metaKey2] = listData2;
    metadataList[metaKey3] = listData3;
    await dispatch(metadataSuccess(metadataList));
    setFetchAll(false);
  };

  const updateClassIdDep = async () => {
    let metadataList = { ...metaData };
    metadataList["batch_list"] = [];
    await dispatch(metadataSuccess(metadataList));
    setValue("batch_list", []);
  };

  useEffect(() => {
    //aplist
    if (fetchAll && watch()?.class_list?.length > 0 && !initialCall) {
      (async () => {
        setinitialCall(true);
        const call1 = await getCollegeFromStateIdForUp(
          watch()?.state_list,
          updateMetaDataMs
        );
        const call2 = await getBatchFromClassIdForMs(
          watch()?.class_list,
          updateMetaDataMs
        );
        const call3= await getCourseFromClassIdReturn(watch()?.class_list,updateMetaDataMs);
        if (call1) {
          updateMetaDataMs(call1[0], call1[1], call2[0], call2[1],call3[0], call3[1]);
        } else {
          updateMetaDataMs([], "college_list", call2[0], call2[1],call3[0], call3[1]);
        }
       
      })();
    }
  }, [watch()?.class_list, watch()?.state_list]);

  const updateMetaDataClassIdChange = async (listData, metaKey,listData2, metaKey2) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    metadataList[metaKey2] = listData2;
    await dispatch(metadataSuccess(metadataList));
    if (metaKey == "batch_list" && listData?.length == 0) {
      setValue("batch_list", []);
    }
    if (metaKey2 == "course_list_dep" && listData2?.length == 0) {
      setValue("course_id", "");
    }
  };

  useEffect(() => {
    if (!fetchAll) {
      setTimeout(() => {
        getCollegeFromStateId(watch()?.state_list, updateMetaData);
      }, 1000);
    }
  }, [watch()?.state_list]);

  // useEffect(() => {
  //   if (!fetchAll) {
  //     if (watch()?.class_list?.length > 0) {
  //       setTimeout(() => {
  //         getBatchFromClassId(watch()?.class_list, updateMetaData);
  //       }, 1000);
  //     } else {
  //       updateClassIdDep();
  //     }
  //   }
  // }, [watch()?.class_list]);

  useEffect(() => {
    if(!fetchAll){
      setTimeout(async() => {
       const call1= await getBatchFromClassIdReturn(watch()?.class_list, updateMetaData);
       const call2= await getCourseFromClassIdReturn(watch()?.class_list, updateMetaData);
       updateMetaDataClassIdChange(call1[0],call1[1],call2[0],call2[1])
      }, 1000);
    }
  }, [watch()?.class_list]);

  useEffect(() => {
    if (metaData?.batch_list?.length > 0 && !isLoad && triggerSetValue) {
      let matchedVales = setReactFormValues(
        metaData,
        "batch_list",
        watch()?.batch_list,
        "batch_id"
      );
      let isEqual = areArraysEqual(watch()?.batch_list, matchedVales);
      if (!isEqual) {
        setValue("batch_list", matchedVales);
      }
    }
  }, [metaData?.batch_list, triggerSetValue]);

  useEffect(() => {
    if (metaData?.college_list?.length > 0 && !isLoad && triggerSetValue) {
      let matchedVales = setReactFormValues(
        metaData,
        "college_list",
        watch()?.college_list,
        "college_id"
      );
      let isEqual = areArraysEqual(watch()?.college_list, matchedVales);
      if (!isEqual) {
        setValue("college_list", matchedVales);
      }
    }
  }, [metaData?.college_list]);

  useEffect(() => {
    if (
      !isEmpty(metaData?.course_list_dep?.length) &&
      !isLoad &&
      triggerSetValue &&
      isEmpty(watch()?.course_id)
    ) {
      const isCourseIdinList = isCourseIdInMetadata(
        metaData?.course_list_dep,
        "course_id",
        watch()?.course_id
      );
      if (!isCourseIdinList) {
        setValue("course_id", "");
      }
    }
  }, [metaData?.course_list_dep]);

  useEffect(() => {
    if (watch()?.is_secondary_course == "true") {
      setValue("is_default_course", "false");
      setValue("is_early_bird_course", "false");
    }
  }, [watch()?.is_secondary_course]);

  useEffect(() => {
    setTimeout(() => {
      setTriggerSetValue(true);
    }, 3000);
  }, []);

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {match?.url?.includes("duplicate") ? "duplicate" : "Update"} course
          Map
        </Typography>
        <Grid container spacing={2}>
          <DynamicForm
            fields={updateStudentCourseProperties(watch())}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
        </Grid>
        <ContentRight sx={{ mt: 1 }}>
          <LoadingButton
            variant="contained"
            loadingPosition="start"
            color="primary"
            onClick={handleClick}
            loading={btnLoading}
            style={{
              width: "180px",
              height: "40px",
              marginTop: "15px",
              padding: "7px 42.5px 7px 48.5px",
              cursor: "pointer",
              textAlign: "center",
            }}
            disabled={
              !isFieldsValidate(
                errors,
                createStdCourseMandatoryFields,
                getValues
              )
            }
          >
            {match?.url?.includes("duplicate") ? "Save as New" : "Update"}
          </LoadingButton>
        </ContentRight>
      </Paper>
    </div>
  );
};
