import * as Yup from "yup";
export const table = {
  primaryData: [
    {
      title: "Platform",
      value: "platform",
      type: "configue",
    },
    {
      title: "App",
      value: "app",
    },
    {
      title: "Version Name",
      value: "version_name",
    },
    {
      title: "Version Number",
      value: "version_number",
    },
    {
      title: "Updated Date",
      value: "updated_at",
      name: "update",
    },
    {
      title: "Updated By",
      value: "updated_username",
      name: "update",
    },
    {
      title: "ActionEdit",
      width: "10%",
    },
  ],
  secondaryData: [],
};

export const updateAppDetailsForm = [
  {
    id: "version_id",
    type: "text",
    label: "version id",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    readOnly: true,
  },
  {
    id: "platform",
    type: "text",
    label: "platform",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    readOnly: true,
  },
  {
    id: "app",
    type: "text",
    label: "app",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    readOnly: true,
  },

  {
    id: "version_name",
    type: "text",
    label: "Enter version Name *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
  },
  {
    id: "version_number",
    type: "text",
    label: "Enter version number *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
  },
  {
    id: "is_force_update",
    type: "normal-select",
    label: "Select is force update *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    //list: "exam_model_list",
    //keyValue: { label: "label", value: "value" },
  },
];

interface updateAppDetailsTypes {
  version_id: number | string;
  version_name: string;
  version_number: string;
  is_force_update: boolean | string;
}

export const updateAppDetailsIntialValues: updateAppDetailsTypes = {
  version_id: "",
  version_name: "",
  version_number: "",
  is_force_update: "",
};

export const UpdateAppDetailsMandatoryFields = [
  { id: "version_name" },
  { id: "version_number" },
  { id: "is_force_update" },
];

export const updateAppValidationSchema = Yup.object().shape({
  version_name: Yup.string().required("version_name is required"),
  version_number: Yup.string().required("version_number is required"),
  is_force_update: Yup.string().required("is_force_update is required"),
});
