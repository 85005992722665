import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import useStyles from "./styles";
import TablePagination from "@mui/material/TablePagination";
import TableMainBody from "./table-body";
import TableTtiles from "./table-titles";
import { TablePaginationActions } from "./table-pagination-action";

export default function SectionTable({
  data,
  handleEdit,
  handleCopy,
  handlePreview,
  rowsPerPageCount,
  pageLimit,
  reqBody,
  pagination,
  totlaRecords,
  paginationSegment,
  hasPageApiCall,
  style,
  id,
  name,
  table,
  search,
  displayFullstring,
  viewSecondaryData,
  showHeader,
  handleDownload
}: any) {
  const styles = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageCount || 10);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (hasPageApiCall != false) {
      const pages = { page_no: newPage + 1, page_limit: rowsPerPage };
      const reqObj = { ...reqBody, ...pages };
      (await hasPageApiCall) != false && hasPageApiCall(reqObj);
      setPage(newPage);
    }

    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    if (hasPageApiCall != false) {
      // setPage(0)
      const pages = { page_no: 1, page_limit: event.target.value };
      const reqObj = { ...reqBody, ...pages };
      (await hasPageApiCall) != false && hasPageApiCall(reqObj);
      // setPage(page);
    }

    setPage(0);
  };
  return (
    <TableContainer sx={{ border: "1px solid #e0e0e0" }}>
      <Table aria-label="collapsible table" size="small">
        <TableHead className={style}>
          <TableRow className={style}>
            <TableTtiles data={table.primaryData} />
          </TableRow>
        </TableHead>

        <TableBody>
          {paginationSegment == "true" &&
            rowsPerPage > 0 &&
            data
              .slice(0, rowsPerPage)
              // : data
              .map((row: any, index: any) => (
                // <Box

                // sx={{fontWeight:row.makeRowBold?"bold":""}}
                // >
                <TableMainBody
                  key={`${id}-${index}`}
                  row={row}
                  handleEdit={handleEdit}
                  handleCopy={handleCopy}
                  handlePreview={handlePreview}
                  id={id}
                  name={name}
                  table={table}
                  displayFullstring={displayFullstring}
                  viewSecondaryData={viewSecondaryData}
                  handleDownload={handleDownload}
                />
                // </Box>
              ))}

          {!(rowsPerPage > 0) &&
            data
              // : data
              .map((row: any, index: any) => (
                <TableMainBody
                  key={`${id}-${index}`}
                  row={row}
                  handleEdit={handleEdit}
                  handleCopy={handleCopy}
                  handlePreview={handlePreview}
                  handleDownload={handleDownload}
                  id={id}
                  name={name}
                  table={table}
                  displayFullstring={displayFullstring}
                  viewSecondaryData={viewSecondaryData}
                />
              ))}

          {paginationSegment == "false" &&
            rowsPerPage > 0 &&
            data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: any) => (
                <TableMainBody
                  key={`${id}-${index}`}
                  row={row}
                  handleEdit={handleEdit}
                  handleCopy={handleCopy}
                  handlePreview={handlePreview}
                  handleDownload={handleDownload}
                  id={id}
                  name={name}
                  table={table}
                  displayFullstring={displayFullstring}
                  viewSecondaryData={viewSecondaryData}
                />
              ))}
        </TableBody>

        {pagination == "true" && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={
                  paginationSegment == "true"
                    ? [
                        rowsPerPageCount,
                        rowsPerPageCount - 10,
                        rowsPerPageCount - 20,
                        rowsPerPageCount - 30,
                        rowsPerPageCount - 40,
                      ]
                    : [
                        rowsPerPageCount,
                        rowsPerPageCount + 5,
                        rowsPerPageCount + 10,
                        rowsPerPageCount + 15,
                        { label: "All", value: -1 },
                      ]
                }
                colSpan={12}
                count={totlaRecords > 0 ? totlaRecords : data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
}
