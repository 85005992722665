import { Box, Button, Grid, Paper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import Loader from "pages/build-test/loader";
import { Link, useHistory } from "react-router-dom";
import ContentRight from "shared/molecules/content-right";
import NoDataFound from "shared/molecules/no-data";
import { TypographyHeading } from "shared/molecules/typography-layout";
import CollapsibleTable from "shared/organisms/tables/collapsible-table";
import { makeStyles } from "@mui/styles";
import { indigo } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { StudentCourseLookupRequest } from "pages/configue/slices/student-course-slice";
import {
  StudentProgramLookupRequest,
  UpdateSingleStdProgramDetails,
} from "pages/configue/slices/student-program-slice";
import { number } from "yup";
import SectionTable from "shared/organisms/tables/table";
import { table } from "./helper";
import configueService from "pages/configue/service";
import { ProgramLookUpFilter } from "./programLookup-filter/ProgramLookUpFilter";
import { isEmpty } from "utils/helpers";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import {
  checkFeatureAccess,
  plateformConfigueUser,
} from "utils/role-restriction/helper";
import { getValuesByIds, META_FIELD_MAP, PC_METADATA_KEYS } from "pages/configue/constants";

const useStyles: any = makeStyles((indigoTheme) => ({
  label: {
    textDecoration: "none",
    textAlign: "center",
    color: indigo[700],
    marginLeft: "80%",
  },
  Btn: {
    backgroundColor: "#3f50b5",
    color: "white",
    width: "90px",
  },
  BtnWrapper: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "30px",
  },
}));

export const StudentProgramLookUp = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { data, error, loading } = useAppSelector(
    (state) => state.studentProgram
  );
  const [programDetails, setProgramDetails] = useState<any>([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [searchPayload, setSearchPayload] = useState({});
  const { isLoading: isLoad, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );
  const { profileData } = useAppSelector((state) => state.gmailLoginReducer);
  const { role: userRoleStr } = profileData;
  const userRole = userRoleStr?.split(",");

  const takeRequiredValue = (classList, key) => {
    const classNameList = classList?.map((SingleClass) => {
      return SingleClass[`${key}`];
    });
    return classNameList;
  };

  const convertDataToTableData = () => {
    const tableData = data.map((item, i) => {
      let singleProgram = { ...item };
      singleProgram["class_list"] = getValuesByIds(
        item?.class_id_list,
        META_FIELD_MAP.CLASS_ID,
        META_FIELD_MAP.CLASS_NAME,
        PC_METADATA_KEYS.CLASS_LIST,
        metaData
      );

      singleProgram["program_name"] = getValuesByIds(
        item?.program_id,
        META_FIELD_MAP.PROGRAM_ID,
        META_FIELD_MAP.PROGRAM_NAME,
        PC_METADATA_KEYS.PROGRAM_LIST,
        metaData
      );

      const object_id =
        item?.match_type === "batch"
          ? META_FIELD_MAP.BATCH_ID
          : META_FIELD_MAP.BOARD_ID;
      const object_name =
        item?.match_type === "batch"
          ? META_FIELD_MAP.BATCH_NAME
          : META_FIELD_MAP.BOARD_NAME;
      const metaKey =
        item?.match_type === "batch"
          ? PC_METADATA_KEYS.BATCH_LIST
          : PC_METADATA_KEYS.BOARD_LIST;

      singleProgram["match_id_list"] = getValuesByIds(
        item?.match_id_list,
        object_id,
        object_name,
        metaKey,
        metaData
      );
      return singleProgram;
    });
    setProgramDetails(tableData);
  };

  const handleEdit = async (std_program_id: any) => {
    const res: any = await configueService.getSingleProgramLookupListApi({
      lookup_id: std_program_id?.id,
      id: std_program_id?.id,
    });
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(UpdateSingleStdProgramDetails(res?.data?.data?.[0]));
      history.push(
        `/configue/student_program_lookup/edit/${std_program_id?.id}`
      );
    }
  };

  const handleCopy = async (std_program_id: any) => {
    const res: any = await configueService.getSingleProgramLookupListApi({
      lookup_id: std_program_id,
      id: std_program_id,
    });
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(UpdateSingleStdProgramDetails(res?.data?.data?.[0]));
      history.push(
        `/configue/student_program_lookup/duplicate/${std_program_id}`
      );
    }
  };

  const handleDelete = async (std_program_id: any) => {
    const res: any = await configueService.deleteProgramLookup({
      lookup_id: std_program_id,
    });
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(snackbarRequest({ message: "deleted successfully" }));
      //history.replace(`/configue/student_program_lookup`);
      dispatch(
        StudentProgramLookupRequest({ ...searchPayload, limit, offset })
      );
    } else {
      dispatch(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  };

  useEffect(() => {
    dispatch(StudentProgramLookupRequest({ ...searchPayload, limit, offset }));
  }, [offset, searchPayload]);

  // const hasModificationAccess=()=>{
  //   return checkFeatureAccess(userRole,plateformConfigueUser?.STD_PROGRAM_LU_READ_WRITE)
  // }

  useEffect(() => {
    if (data.length > 0) {
      // covert data intotable formate
      convertDataToTableData();
    } else if (isEmpty(data)) {
      setProgramDetails([]);
    }
  }, [data]);

  const hasModificationAccess = checkFeatureAccess(
    userRole,
    plateformConfigueUser?.STD_PROGRAM_LU_READ_WRITE
  );

  return (
    <div>
      <ContentRight>
        {hasModificationAccess && (
          <Link
            to="/configue/student_program_lookup/create"
            className={classes.label}
          >
            <Button variant="contained">Create New Program Lookup</Button>
          </Link>
        )}
      </ContentRight>
      <TypographyHeading>Program Lookup</TypographyHeading>
      <Paper sx={{ mt: 2, p: 2 }}>
        <Box sx={{ mt: 2, mb: 2 }}>
          <ProgramLookUpFilter
            setSearchPayload={setSearchPayload}
            setOffset={setOffset}
          />
        </Box>
        {loading && <Loader />}
        {!loading && programDetails.length > 0 && !error ? (
          // <SectionTable
          //   data={programDetails}
          //   table={table}
          //   id="id"
          //   name="version_id"
          //   handleEdit={handleEdit}
          //   handleCopy={handleCopy}
          //   rowsPerPageCount={10}
          //   pagination="false"
          //   style="false"
          //   totlaRecords={0}
          //   // hasPageApiCall={false}
          //   paginationSegment="false"
          //   viewSecondaryData="false"
          // />
          <CollapsibleTable
            data={programDetails}
            table={table}
            id="id"
            handleEdit={hasModificationAccess ? handleEdit : ""}
            handleCopy={hasModificationAccess ? handleCopy : ""}
            handleDelete={hasModificationAccess ? handleDelete : ""}
            rowsPerPageCount={20}
            paginationSegmentApi="false"
            hasPageApiCall={false}
            showPaginationFooter={false}
          />
        ) : (
          <div>
            {!loading && (
              <NoDataFound message="No student program List found, Please create new List" />
            )}
          </div>
        )}

        <Box className={classes.BtnWrapper}>
          <Button
            onClick={() => {
              setOffset(offset - 10);
            }}
            className={classes.Btn}
            disabled={offset == 0}
            variant="contained"
            size="medium"
          >
            prev
          </Button>
          <Button
            onClick={() => {
              setOffset(offset + 10);
            }}
            className={classes.Btn}
            disabled={data.length <= 9}
            variant="contained"
            size="medium"
          >
            next
          </Button>
        </Box>
      </Paper>
    </div>
  );
};

//   <CollapsibleTable
//     data={data}
//    // table={table}
//     id="delivery_id"
//     // handleEdit={handleEdit}
//     // handleCopy={handleCopy}
//     rowsPerPageCount={20}
//     paginationSegmentApi="false"
//     hasPageApiCall={false}
//   />
