export const table = {
  primaryData: [
    {
      title: "Mapping ID",
      value: "narayana_mapping_id",
      type: "configue",
    },
    {
      title: "Name",
      value: "test_name",
      type: "configue",
    },
    {
      title: "cbd_xml_updated_time",
      value: "xml_updated_time",
      name: "update",
    },
    {
      title: "sbd_xml_downloaded_time",
      value: "xml_downloaded_time",
    },
    {
      title: "sbd_analysis_completed_status",
      value: "analytics_status",
    },
    {
      title: "Start Time",
      value: "test_start_time",
      type: "configue",
    },
    {
      title: "End Time",
      value: "test_end_time",
      type: "configue",
    },
    {
      title: "S & S Count*",
      value: "submitted_or_suspended_count",
    },
    {
      title: "S & P Count*",
      value: "started_or_progress_count",
    },
  ],
  secondaryData: [],
};

//   {
//     "testId": "AI7U7kVDue",
//     "narayana_mapping_id": 101333,
//     "test_name": "jeeadv2011",
//     "test_start_time": "2024-09-04T06:30:00.000Z",
//     "test_end_time": "2024-09-14T12:30:00.000Z",
//     "analytics_status": false,
//     "download_status": false,
//     "xml_downloaded_time": "-",
//     "xml_updated_time": "-",
//     "submitted_or_suspended_count": 20,
//     "started_or_progress_count": 9
// },
