import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";

export const SearchFieldSingleSelect = ({
  handleSearch,
  field,
  setSearchField,
  searchField,
  triggerOptionList,
  setTriggerOptionList,
  setSearchOptions
}) => {
  useEffect(() => {
    setSearchField("");
    setTriggerOptionList(!triggerOptionList);
    return setSearchOptions([])
  }, []);
  return (
    <>
      <TextField
        sx={{
          textAlign: "center",
          width: "100%",
          mb: 1,
          paddingRight: "10px",
        }}
        fullWidth
        id={field.title}
        value={searchField}
        placeholder="Type to search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(e) => handleSearch(e)}
        autoFocus
        onKeyDown={(e) => {
          if (e.key !== "Escape") {
            e.stopPropagation();
          }
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </>
  );
};
