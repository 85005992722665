import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  UpdateSingleAppDetails,
  forceUpdateRequest,
} from "pages/configue/slices/force-update-slice";
import { useEffect, useState } from "react";
import SectionTable from "shared/organisms/tables/table";
import { table } from "./helper";
import { useHistory } from "react-router-dom";
import { Box, Paper, Typography,Button } from "@mui/material";
import Loader from "pages/build-test/loader";
import {
  checkFeatureAccess,
  plateformConfigueUser,
} from "utils/role-restriction/helper";
import { makeStyles } from "@mui/styles";
import { indigo } from "@mui/material/colors";
import { isEmpty } from "utils/helpers";
import NoDataFound from "shared/molecules/no-data";

const useStyles: any = makeStyles((indigoTheme) => ({
  label: {
    textDecoration: "none",
    textAlign: "center",
    color: indigo[700],
    marginLeft: "80%",
  },
  Btn: {
    backgroundColor: "#3f50b5",
    color: "white",
    width: "90px",
  },
  BtnWrapper: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

export const ForceUpdate = () => {
  const dispatch = useAppDispatch();
  const [appDetails, setAppDetails] = useState<any>([]);
  const { data, loading } = useAppSelector((state) => state.forceUpdateDetails);
  const history = useHistory();
  const classes = useStyles();
  const { profileData } = useAppSelector((state) => state.gmailLoginReducer);
  const { role: userRoleStr } = profileData;
  const userRole = userRoleStr?.split(",");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  //Loader
  const handleEdit = (version_id: any) => {
    const getSelectedApp = data.filter((item) => {
      if (item.version_id == version_id) {
        return item;
      }
    });
    if (getSelectedApp.length > 0) {
      dispatch(UpdateSingleAppDetails(getSelectedApp[0]));
    }
    history.push(`/configue/force_update/edit/${version_id}`);
  };

  function formatDate(isoString) {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  const convertDataToTableData = () => {
    const tableData = data.map((item, i) => {
      let singlePlateform = { ...item };
      singlePlateform["is_force_update"] =
        item.is_force_update === true ? "true" : "false";
      // singlePlateform["created_at"] = formatDate(item.created_at);
      //singlePlateform["updated_at"] = formatDate(item.updated_at);
      return singlePlateform;
    });
    setAppDetails(tableData);
  };

  // const hasModificationAccess=()=>{
  //   return checkFeatureAccess(userRole,plateformConfigueUser?.FORCE_UPDATE_READ_WRITE)
  // }

  useEffect(() => {
    dispatch(forceUpdateRequest({limit,offset}));
  }, [offset]);

  useEffect(() => {
    if (data.length > 0) {
      // covert data intotable formate
      convertDataToTableData();
    }else if(isEmpty(data)){
      setAppDetails([]);
    }
  }, [data]);

  const hasModificationAccess = checkFeatureAccess(
    userRole,
    plateformConfigueUser?.FORCE_UPDATE_READ_WRITE
  );

  return (
    <div>
      <Typography variant="h4" sx={{ marginBottom: "20px" }}>
        App Details
      </Typography>
      <Paper elevation={2} sx={{ p: 2 }}>
        <div>
          {loading && <Loader />}
          {!loading && appDetails.length > 0 ? (
            <SectionTable
              data={appDetails}
              table={table}
              id="version_id"
              name="version_id"
              handleEdit={hasModificationAccess ? handleEdit : ""}
              //   handleCopy={handleCopy}
              rowsPerPageCount={50}
              // pagination="true"
              style="false"
              totlaRecords={0}
              // hasPageApiCall={false}
              paginationSegment="false"
              viewSecondaryData="false"
            />
          ) : (
            <div>{!loading && <NoDataFound message="No Data" />}</div>
          )}
        </div>

        <Box className={classes.BtnWrapper}>
          <Button
            onClick={() => {
              setOffset(offset - 10);
            }}
            className={classes.Btn}
            disabled={offset == 0}
            variant="contained"
            size="medium"
          >
            prev
          </Button>
          <Button
            onClick={() => {
              setOffset(offset + 10);
            }}
            className={classes.Btn}
            disabled={data.length <= 9}
            variant="contained"
            size="medium"
          >
            next
          </Button>
        </Box>
      </Paper>
    </div>
  );
};
